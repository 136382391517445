/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Notifications from "views/Notifications.js";
import Icons from "views/Icons.js";
import Typography from "views/Typography.js";
import TableList from "views/TableList.js";
import Maps from "views/Maps.js";
import Upgrade from "views/Upgrade.js";
import UserPage from "views/UserPage.js";
import Statistic from "./views/Statistic";
import Finance from "./views/Finance";
import Contacts from "./views/Contacts";
import Logout from "./views/Logout";

var dashRoutes = [
  {
    path: "/stats",
    name: "Statistics",
    icon: "business_chart-bar-32",
    component: Statistic,
    layout: "/admin",
  },
  {
    path: "/balance",
    name: "Finance",
    icon: "business_money-coins",
    component: Finance,
    layout: "/admin",
  },
  {
    path: "/user-page",
    name: "User Profile",
    icon: "users_single-02",
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/contacts",
    name: "Contacts",
    icon: "travel_info",
    component: Contacts,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "travel_info",
    component: Logout,
    layout: "/admin",
  }
];
export default dashRoutes;
