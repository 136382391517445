import React from "react";

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input, Button,
    Table
} from "reactstrap"

import { Router, NavLink } from "react-router-dom";

import SweetAlert from 'react-bootstrap-sweetalert';

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {Line} from "react-chartjs-2";

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import {createBrowserHistory} from "history";

const dateObj = new Date();
const month = to2digits(dateObj.getUTCMonth() + 1); //months from 1-12
const day = to2digits(dateObj.getUTCDate());
const year = dateObj.getUTCFullYear();
export const today = year + '-' + month + '-' + day;

const weekAgo = new Date(dateObj.getTime() - 7 * 24 * 60 * 60 * 1000);
const month7 = to2digits(weekAgo.getUTCMonth() + 1); //months from 1-12
const day7 = to2digits(weekAgo.getUTCDate());
const year7 = weekAgo.getUTCFullYear();
export const prevWeek = year7 + '-' + month7 + '-' + day7;



class Statistic extends React.Component{

    state = {
        chartI : {
            data:{},
            options: {
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 0,
                        bottom: 0,
                    },
                },
                maintainAspectRatio: false,

                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display:false,
                            },
                            gridLines: {
                                display: false,
                            },
                            id : "i",
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display:false,
                            },
                        },
                    ],
                },
            },
        },
        chartC : {
            data:{},
            options: {
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 0,
                        bottom: 0,
                    },
                },
                maintainAspectRatio: false,

                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display:false,
                            },
                            gridLines: {
                                display: false,
                            },
                            id : "i",
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display:false,
                            },
                        },
                    ],
                },
            },
        },
        chartS : {
            data:{},
            options: {
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 0,
                        bottom: 0,
                    },
                },
                maintainAspectRatio: false,

                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display:false,
                            },
                            gridLines: {
                                display: false,
                            },
                            id : "i",
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display:false,
                            },
                        },
                    ],
                },
            },
        },
        chartLoaded:false,
        order:'asc',
        orderBy:'cid',
        page:0,
        rowsPerPage:25,
        dateFrom : prevWeek,
        dateTo : today,
        items: [],
        loaded:false,
        mounted : false,
        sum : {
            'reqs' : 0,
            'wins' : 0,
            'imps' : 0,
            'clicks' : 0,
            'spent' : 0,
        },
        campaign : ""

    };

    getChart = () => {

        this.setState({mounted:false});

        fetch(`/api?function=getChart&dateFrom=${this.state.dateFrom}&dateTo=${this.state.dateTo}`,)
            .then((res => res.json()))
            .then(
                (result) => {
                    this.setState({
                        chartI:{
                            data: (canvas) => {
                                const ctx = canvas.getContext("2d");
                                var chartColor = "#FFFFFF";
                                var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                                gradientStroke.addColorStop(0, "#80b6f4");
                                gradientStroke.addColorStop(1, chartColor);
                                var gradientFillW = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillW.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillW.addColorStop(1, "rgba(255, 255, 255, 0.14)");

                                var gradientFillR = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillR.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillR.addColorStop(1, "rgba(255, 0, 0, 0.14)");

                                var gradientFillG = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillG.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillG.addColorStop(1, "rgba(0, 255, 0, 0.14)");

                                return {
                                    labels: result.labels,
                                    datasets: [
                                        {
                                            label:"Impressions",
                                            borderColor: "#ff0000",
                                            pointBorderColor: "#ff0000",
                                            pointBackgroundColor: "#2c2c2c",
                                            pointHoverBackgroundColor: "#2c2c2c",
                                            pointHoverBorderColor: chartColor,
                                            pointBorderWidth: 1,
                                            pointHoverRadius: 2,
                                            fill: true,
                                            borderWidth: 2,
                                            data: result.imps,
                                            yAxisID: "i",
                                        },
                                    ],
                                };
                            },
                            options: {
                                layout: {
                                    padding: {
                                        left: 20,
                                        right: 20,
                                        top: 0,
                                        bottom: 0,
                                    },
                                },
                                maintainAspectRatio: false,
                                tooltips: {
                                    backgroundColor: "#eee",
                                    titleFontColor: "#333",
                                    bodyFontColor: "#666",
                                    bodySpacing: 4,
                                    xPadding: 12,
                                    mode: "nearest",
                                    intersect: 0,
                                    position: "nearest",
                                    callbacks: {
                                        label: function(tooltipItem, data) {
                                            return data.datasets[tooltipItem.datasetIndex].label +
                                                ' : ' +
                                                new Intl.NumberFormat('ru-Ru').format(tooltipItem.yLabel)
                                        }
                                    }
                                },
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                display:false,
                                            },
                                            gridLines: {
                                                display: false,
                                            },
                                            id : "i",
                                        },
                                    ],
                                    xAxes: [
                                        {
                                            gridLines: {
                                                display: false,
                                            },
                                            ticks: {
                                                display:false,
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        chartS:{
                            data: (canvas) => {
                                const ctx = canvas.getContext("2d");
                                var chartColor = "#FFFFFF";
                                var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                                gradientStroke.addColorStop(0, "#80b6f4");
                                gradientStroke.addColorStop(1, chartColor);
                                var gradientFillW = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillW.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillW.addColorStop(1, "rgba(255, 255, 255, 0.14)");

                                var gradientFillR = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillR.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillR.addColorStop(1, "rgba(255, 0, 0, 0.14)");

                                var gradientFillG = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillG.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillG.addColorStop(1, "rgba(0, 255, 0, 0.14)");

                                return {
                                    labels: result.labels,
                                    datasets: [
                                        {
                                            label:"Spent",
                                            borderColor: "#0000ff",
                                            pointBorderColor: "#0000ff",
                                            pointBackgroundColor: "#2c2c2c",
                                            pointHoverBackgroundColor: "#2c2c2c",
                                            pointHoverBorderColor: chartColor,
                                            pointBorderWidth: 1,
                                            pointHoverRadius: 2,
                                            fill: true,
                                            borderWidth: 2,
                                            data: result.spent,
                                            yAxisID: "i",
                                        },
                                    ],
                                };
                            },
                            options: {
                                layout: {
                                    padding: {
                                        left: 20,
                                        right: 20,
                                        top: 0,
                                        bottom: 0,
                                    },
                                },
                                maintainAspectRatio: false,
                                tooltips: {
                                    backgroundColor: "#eee",
                                    titleFontColor: "#333",
                                    bodyFontColor: "#666",
                                    bodySpacing: 4,
                                    xPadding: 12,
                                    mode: "nearest",
                                    intersect: 0,
                                    position: "nearest",
                                    callbacks: {
                                        label: function(tooltipItem, data) {
                                            return data.datasets[tooltipItem.datasetIndex].label +
                                                ' : ' +
                                                new Intl.NumberFormat('ru-Ru').format(tooltipItem.yLabel)
                                        }
                                    }
                                },
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                display:false,
                                            },
                                            gridLines: {
                                                display: false,
                                            },
                                            id : "i",
                                        },
                                    ],
                                    xAxes: [
                                        {
                                            gridLines: {
                                                display: false,
                                            },
                                            ticks: {
                                                display:false,
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        chartC:{
                            data: (canvas) => {
                                const ctx = canvas.getContext("2d");
                                var chartColor = "#FFFFFF";
                                var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                                gradientStroke.addColorStop(0, "#80b6f4");
                                gradientStroke.addColorStop(1, chartColor);
                                var gradientFillW = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillW.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillW.addColorStop(1, "rgba(255, 255, 255, 0.14)");

                                var gradientFillR = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillR.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillR.addColorStop(1, "rgba(255, 0, 0, 0.14)");

                                var gradientFillG = ctx.createLinearGradient(0, 200, 0, 50);
                                gradientFillG.addColorStop(0, "rgba(128, 182, 244, 0)");
                                gradientFillG.addColorStop(1, "rgba(0, 255, 0, 0.14)");

                                return {
                                    labels: result.labels,
                                    datasets: [
                                        {
                                            label:"Clicks",
                                            borderColor: "#008800",
                                            pointBorderColor: "#008800",
                                            pointBackgroundColor: "#2c2c2c",
                                            pointHoverBackgroundColor: "#2c2c2c",
                                            pointHoverBorderColor: chartColor,
                                            pointBorderWidth: 1,
                                            pointHoverRadius: 2,
                                            fill: true,
                                            borderWidth: 2,
                                            data: result.clicks,
                                            yAxisID: "i",
                                        },
                                    ],
                                };
                            },
                            options: {
                                layout: {
                                    padding: {
                                        left: 20,
                                        right: 20,
                                        top: 0,
                                        bottom: 0,
                                    },
                                },
                                maintainAspectRatio: false,
                                tooltips: {
                                    backgroundColor: "#eee",
                                    titleFontColor: "#333",
                                    bodyFontColor: "#666",
                                    bodySpacing: 4,
                                    xPadding: 12,
                                    mode: "nearest",
                                    intersect: 0,
                                    position: "nearest",
                                    callbacks: {
                                        label: function(tooltipItem, data) {
                                            return data.datasets[tooltipItem.datasetIndex].label +
                                                ' : ' +
                                                new Intl.NumberFormat('ru-Ru').format(tooltipItem.yLabel)
                                        }
                                    }
                                },
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                display:false,
                                            },
                                            gridLines: {
                                                display: false,
                                            },
                                            id : "i",
                                        },
                                    ],
                                    xAxes: [
                                        {
                                            gridLines: {
                                                display: false,
                                            },
                                            ticks: {
                                                display:false,
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        chartLoaded:true}
                    )
                }
            );
        this.setState({mounted:true})
    }

    componentDidMount() {
        this.loadStat();
    }

    loadStat = () => {
        this.getChart();

        this.setState({
            items: [],
            loaded:false,
            sum : {
                'reqs' : 0,
                'wins' : 0,
                'imps' : 0,
                'clicks' : 0,
                'spent' : 0,
            },})
        fetch(`/api?function=getStatistic&dateFrom=${this.state.dateFrom}&dateTo=${this.state.dateTo}`,)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({loaded:true,items:result});
                    result.map((row) => {
                        this.setState({sum:{
                                'reqs' : this.state.sum.reqs += row.reqs,
                                'clicks' : this.state.sum.clicks += row.clicks,
                                'wins' : this.state.sum.wins += row.wins,
                                'imps': this.state.sum.imps += row.imps,
                                'spent' : this.state.sum.spent += row.spent,
                            }});
                    })
                },
                // Примечание: Обрабатывать ошибки необходимо именно здесь
                // вместо блока catch(), чтобы не пропустить
                // исключения из реальных ошибок в компонентах.
                (error) => {
                    this.setState({loaded:true})
                }
            )
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({order:isAsc ? 'desc' : 'asc',orderBy:property});
    };


    handleChangePage = (event, newPage) => {
        this.setState({page:newPage})
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage:parseInt(event.target.value, 10),page:0});
    };

    nextPage = () => {
        this.setState({page:this.state.page + 1});
        console.log((this.state.page))
    };
    prevPage = () => {
        if (this.state.page > 0) {
            this.setState({page:this.state.page - 1})
        }
    };

    render() {

        return (
            <>
               <PanelHeader size="sm"/>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={3}>
                                            <FormGroup>
                                                <label htmlFor="date-from">
                                                    Date from
                                                </label>
                                                <Input
                                                    id="date-from"
                                                    type="date"
                                                    value={this.state.dateFrom}
                                                    onChange={e => this.setState({dateFrom:e.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3}>
                                            <FormGroup>
                                                <label htmlFor="date-to">
                                                    Date to
                                                </label>
                                                <Input
                                                    id="date-to"
                                                    type="date"
                                                    value={this.state.dateTo}
                                                    onChange={e => this.setState({dateTo:e.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3}>
                                            <FormGroup>
                                                <label htmlFor="campaign">
                                                    Search by name
                                                </label>
                                                <Input
                                                    id="campaign"
                                                    value={this.state.campaign}
                                                    onChange={e => this.setState({campaign:e.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} className="d-flex align-items-end">
                                            <FormGroup className="d-flex align-items-end mb-1">
                                                <Button
                                                    color="primary"
                                                    className="d-flex align-items-center"
                                                    onClick={e => this.loadStat()}
                                                >
                                                    Show
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <Card>
                                <CardBody>
                                    <Line
                                        data={this.state.chartI.data}
                                        options={this.state.chartI.options}
                                    />
                                </CardBody>
                            </Card>

                        </Col>
                        <Col xs={4}>
                            <Card>
                                <CardBody>
                                    <Line
                                        data={this.state.chartC.data}
                                        options={this.state.chartC.options}
                                    />
                                </CardBody>
                            </Card>

                        </Col>
                        <Col xs={4}>
                            <Card>
                                <CardBody>
                                    <Line
                                        data={this.state.chartS.data}
                                        options={this.state.chartS.options}
                                    />
                                </CardBody>
                            </Card>

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {!this.state.loaded ? 'Loading' :
                                        <>
                                            <Table bordered >
                                                <EnhancedTableHead  onRequestSort={this.handleRequestSort}  order={this.state.order} orderBy={this.state.orderBy}/>
                                                <TableBody>
                                                    {stableSort(this.state.items,getComparator(this.state.order,this.state.orderBy))
                                                        .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                        .map((row) => {
                                                            let pattern = ".*" + this.state.campaign + ".*";
                                                            let regexp = new RegExp(pattern,"i");
                                                            if (row.name.match(regexp)) {
                                                                return (
                                                                    <TableRow key={row.id}>
                                                                        <TableCell>{row.id}</TableCell>
                                                                        <TableCell>{row.name}</TableCell>
                                                                        <TableCell align="right">{new Intl.NumberFormat('ru-RU').format(row.reqs)}</TableCell>
                                                                        <TableCell align="right">{new Intl.NumberFormat('ru-RU').format(row.imps)}</TableCell>
                                                                        <TableCell align="right">{new Intl.NumberFormat('ru-RU').format(row.clicks)}</TableCell>
                                                                        <TableCell align="right">{new Intl.NumberFormat('ru-RU').format(row.spent)}</TableCell>
                                                                        <TableCell><Actions cid={row.id}/></TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TableCell>—</TableCell>
                                                        <TableCell>—</TableCell>
                                                        <TableCell align="right">{new Intl.NumberFormat('ru-RU').format(this.state.sum.reqs)}</TableCell>
                                                        <TableCell align="right">{new Intl.NumberFormat('ru-RU').format(this.state.sum.imps)}</TableCell>
                                                        <TableCell align="right">{new Intl.NumberFormat('ru-RU').format(this.state.sum.clicks)}</TableCell>
                                                        <TableCell align="right">{new Intl.NumberFormat('ru-RU').format(this.state.sum.spent)}</TableCell>
                                                        <TableCell>—</TableCell>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                            <div className="w-100 justify-content-end" style={{display:"flex"}}>
                                                <Button color="primary" onClick={this.prevPage}>
                                                    <i className="now-ui-icons arrows-1_minimal-left"/>
                                                </Button>
                                                <Button color="primary" onClick={this.nextPage}>
                                                    <i className="now-ui-icons arrows-1_minimal-right"/>
                                                </Button>
                                            </div>

                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const headCells = [
    { id: 'id', numeric: false, disablePadding: false, label: 'ID',actions:false },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name',actions:false },
    { id: 'reqs', numeric: true, disablePadding: false, label: 'Reqs',actions:false },
    { id: 'imps', numeric: true, disablePadding: false, label: 'Imps',actions:false },
    { id: 'clicks', numeric: true, disablePadding: false, label: 'Clicks',actions:false },
    { id: 'spent', numeric: true, disablePadding: false, label: 'Spent, €',actions:false },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Actions',actions:true}
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class EnhancedTableHead extends React.Component{

    order = this.props.order;
    orderBy = this.props.orderBy;
    onRequestSort = this.props.onRequestSort;

    createSortHandler = (property) => (event) => {
        this.onRequestSort(event, property);
    };

    render() {
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : headCell.actions ? 'center' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={this.orderBy === headCell.id ? this.order : false}
                        >
                            <TableSortLabel
                                active={this.orderBy === headCell.id}
                                direction={this.orderBy === headCell.id ? this.order : 'asc'}
                                onClick={this.createSortHandler(headCell.id)}
                            >
                                {headCell.label.replace(/ /g, "\u00a0")}
                                {this.orderBy === headCell.id ? (
                                    <span >
                          {this.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

}

const hist = createBrowserHistory();

class Actions extends React.Component{
    state = {
        linkEdit : `/admin/edit/${this.props.cid}`,
        stop:false,
    };

    render() {
        return (
            <Row className="ml-5">
                <Router history={hist}>
                    <a  href={this.state.linkEdit} style={{color:"black"}}>
                        <Col xs={1} >
                            <i className="now-ui-icons travel_info" />
                        </Col>
                    </a>
                </Router>
                <Col xs={1} onClick={()=>this.setState({stop:true})}>
                    <i className="now-ui-icons ui-1_simple-remove" />
                </Col>
                <SweetAlert
                    error
                    show={this.state.stop}
                    onConfirm={() => this.setState({ stop: false })}
                    title="Denied"
                />
            </Row>

        )
    }

}

export default Statistic

function to2digits(number) {
    if (number < 10)
        return '0' + number;

    return number
}
