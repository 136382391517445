import React, {useEffect} from 'react';

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    UncontrolledTooltip
} from "reactstrap";


import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { thead, tbody } from "variables/general";


class Finance extends React.Component{

    state = {
        result : {},
        loaded : false,
        error : false,
        rowsPerPage:20,
        page:0
    };

    componentDidMount() {
        fetch('/api?function=getFinance',)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({result:result});
                    this.setState({loaded:true});
                },
                (error) => {
                    this.setState({loaded:true});
                    this.setState({error:error})
                }
            );
    }

    nextPage = () => {
        this.setState({page:this.state.page + 1});
        console.log((this.state.page))
    };
    prevPage = () => {
        if (this.state.page > 0) {
            this.setState({page:this.state.page - 1})
        }
    };


    render() {

        if (this.state.error) {
            return (
                <>
                    <PanelHeader size="sm" />
                    <div className="content">
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardTitle>
                                        <h4 align="center">Balance</h4>
                                    </CardTitle>
                                    <CardBody>
                                        <div>Error : {this.error.message}</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        } else if (!this.state.loaded) {
            return (
                <>
                    <PanelHeader size="sm" />
                    <div className="content">
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardTitle>
                                        <h4 align="center">Balance</h4>
                                    </CardTitle>
                                    <CardBody>
                                        <div>Loading ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        }else{
            return (
                <>
                    <PanelHeader size="sm" />
                    <div className="content">
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <CardTitle>
                                            <h4 align="center">Balance</h4>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Table bordered hover className="w-auto m-auto">
                                            <thead>
                                                <tr>
                                                    <th>Month</th>
                                                    <th className="text-right">Amount</th>
                                                    <th>Description</th>
                                                    <th>Download</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.result.map((el, index) => [el, index])
                                                    .map((el) => el[0])
                                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                    .map((row) => {
                                                return (
                                                    <tr>
                                                        <td>{row.date}</td>
                                                        <td className="text-right">{new Intl.NumberFormat('ru-RU').format(row.amount)} {row.currency}</td>
                                                        <td>{row.comment}</td>
                                                        <td>{row.amount < 0 ? <Actions date={row.date}/> : ""}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                        <div className="w-100 justify-content-center d-flex">
                                            <Button color="primary" onClick={this.prevPage} className="ml-lg-5">
                                                <i className="now-ui-icons arrows-1_minimal-left"/>
                                            </Button>
                                            <Button color="primary" onClick={this.nextPage}>
                                                <i className="now-ui-icons arrows-1_minimal-right"/>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )

        }
    }

}

export default Finance

class Actions extends React.Component{
    render() {
        const linkAct = `/api?function=getStats&date=${this.props.date}`;


        /*
        * <Col xs={1} className="ml-5">
                    <a href={linkStats}  id="stat">
                        <Button color="info" className="d-flex align-items-center">Statistic&nbsp;&nbsp;<i className="now-ui-icons arrows-1_cloud-download-93" /></Button>
                    </a>
                    <UncontrolledTooltip placement="top" target="stat" delay={0}>
                        Download Statistics
                    </UncontrolledTooltip>
                </Col>
        * */

        return (
            <Row className="m-auto">
                <Col xs={1}>
                    <a href={linkAct} id="act" className="text-decoration-none">
                        <Button color="primary" className="d-flex align-items-center"><i className="now-ui-icons arrows-1_cloud-download-93" /></Button>
                    </a>
                    <UncontrolledTooltip placement="top" target="act" delay={0}>
                       Download Act
                    </UncontrolledTooltip>
                </Col>

            </Row>


        )
    }
}

